import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_OFFERS, ADMIN_PRODUCTS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_PRODUCT } from 'Consts/activityLogs';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/products/Editor';
import SpecializationsList from 'Components/admin/specializations/List';
import { TAB_OFFERS, TAB_PRODUCTS } from 'Components/pages/admin/Offers/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_SPECIALIZATIONS_LIST = 'specializationsList';
export const TAB_LOGS = 'logs';

export default class AdminProductsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
            assignSpecialization: PropTypes.func.isRequired,
            unassignSpecialization: PropTypes.func.isRequired,
        }).isRequired,
        product: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { actions, product, location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return (
            <StyledComponent
                className="admin-products-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={product}
                >
                    {product && product.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_OFFERS }),
                                label: 'Oferty',
                            }, {
                                to: withVariables(ADMIN_OFFERS.path, {}, { tab: TAB_PRODUCTS }),
                                label: 'Produkty',
                            }, {
                                to: withVariables(ADMIN_PRODUCTS_MANAGE.path, { id: product.data.id }),
                                label: 'Edytuj produkt',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj produkt"
                                subHeadline={product.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={product.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_SPECIALIZATIONS_LIST,
                                    label: 'Specjalizacje',
                                    children: (
                                        <SpecializationsList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                productId: product.data.id || undefined,
                                            }}
                                            onMapControls={(element, controls) => ([
                                                ...controls,
                                                {
                                                    type: 'button',
                                                    label: 'Odpisz',
                                                    visible: !queryObject.assigned,
                                                    onClick: () => actions.unassignSpecialization({ specializationIds: [element.id], id: product.data.id })
                                                        .then(() => {
                                                            history.push(
                                                                withVariables(
                                                                    location.pathname,
                                                                    {},
                                                                    { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                                )
                                                            );
                                                        }),
                                                }, {
                                                    type: 'button',
                                                    label: 'Przypisz',
                                                    visible: queryObject.assigned === 'false',
                                                    onClick: () => actions.assignSpecialization({ specializationIds: [element.id], id: product.data.id })
                                                        .then(() => {
                                                            history.push(
                                                                withVariables(
                                                                    location.pathname,
                                                                    {},
                                                                    { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                                )
                                                            );
                                                        }),
                                                },
                                            ])}
                                            onMapFilters={filters => ([
                                                ...filters,
                                                {
                                                    label: 'Przypisanie',
                                                    type: 'select',
                                                    name: 'assigned',
                                                    inputProps: {
                                                        options: [{
                                                            label: 'Przypisane',
                                                            value: '',
                                                        }, {
                                                            label: 'Nieprzypisane',
                                                            value: 'false',
                                                        }],
                                                    },
                                                },
                                            ])}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_PRODUCT,
                                                dataId: product.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}